import React from "react"

import Page from "../../components/Page"
import BannerParallax from "../../components/BannerParallax"

import imgBackground from "../../images/banner/banner-3-concert-hall.jpg"
import ConcertHall from "./ConcertHall"

export default () => {
  const pageTitle = "Concert Hall"
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <ConcertHall />
    </Page>
  )
}
