import React from "react";
import imgConcertHall from "../../images/concert-hall-music-republicain.jpg";
import imgConcertHall01 from "../../images/concert-hall/01.jpg";
import imgConcertHall02 from "../../images/concert-hall/02.jpg";
import imgConcertHall03 from "../../images/concert-hall/03.jpg";
import imgConcertHall04 from "../../images/concert-hall/04.jpg";
import Image from "../../components/Image";

export default () => {
  return (
    <section className="section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <div className="section_title text-center">
              <h2>Concert Hall</h2>
            </div>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-md-6">
            <section>
              <h3>General Information</h3>
              <p>
                Music Républicain concert hall is a multi-purpose hall that is
                designed to accommodate various musical activities. From
                ensemble, orchestra, theatre, opera, broadway show, concerts,
                seminars, all the way to competitions. This concert hall has
                been our most precious gem for over a decade. It was specially
                designed by the renowned US-based acoustic expert, Hadi Sumoro,
                to create an all-around warm acoustic sound wherever you're
                seated. Oozing Old Hollywood charm and being spacious enough to
                seat 140 people, this hall is a local favorite.
              </p>
            </section>
            <section>
              <h3>Architectural and Structural Details</h3>
              <p>
                <ul>
                  <li>Walls: Red brick base, Hebel brick and Calsi-board.</li>
                  <li>Ceiling: Calsi-board.</li>
                  <li>Floor: Wood and vinyl on wooden joists.</li>
                  <li>Stage: Concrete enclosure with wood finish.</li>
                  <li>Seating: Portable steel chair.</li>
                  <li>Volume: 579m3.</li>
                  <li>Area: 126m2.</li>
                  <li>Seating capacity: ±140.</li>
                  <li>Height: 4,6m.</li>
                  <li>Stage area: 36m2.</li>
                </ul>
              </p>
            </section>
            <section>
              <h3>Acoustical Data</h3>
              <p>
                <ul>
                  <li>
                    Reverberation time (mid-frequency): 1,07s (unoccupied).
                  </li>
                  <li>
                    Articulation loss: 5,755%. STI: 0,629.{" "}
                    <small>
                      (Note: Reverberation measurement does not follow ISO 3382)
                    </small>
                  </li>
                </ul>
              </p>
            </section>
            <section>
              <h3>Sound System Equipments</h3>
              <p>
                <ul>
                  <li>Main full-range loudspeakers: Community SLS920.</li>
                  <li>Subwoofer: Custom 2x 12” by Atok Purnomo.</li>
                  <li>
                    Amplifiers: Dynacord S-1200, Absolute Design AD5000,
                    Absolute Design DJ1200.
                  </li>
                  <li>Mixer: Yamaha MG24/14FX.</li>
                  <li>DSP: EV DC-One, dBx Driverack PA.</li>
                </ul>
              </p>
            </section>
            <section>
              <h3>Lighting Equipments</h3>
              <p>
                <ul>
                  <li>24 LED Par Lights.</li>
                  <li>4 Moving Heads.</li>
                  <li>2 Laser Lights.</li>
                  <li>1 Follow Spot. </li>
                  <li>1 Fogger.</li>
                </ul>
              </p>
            </section>
            <section>
              <h3>Consultants</h3>
              <p>
                <ul>
                  <li>Acoustics Consultant: YP Hadi Sumoro K</li>
                  <li>Architect: Eko Effendy Sugianto</li>
                  <li>Audio System Consultant: YP Hadi Sumoro K</li>
                  <li>Lighting Consultant: Donie Debirkud</li>
                </ul>
              </p>
            </section>
          </div>
          <div className="col-md-6">
            <div className="mb-2">
              <Image
                className="w-100 p-1"
                src={imgConcertHall}
                alt="concert hall music republicain"
              />
            </div>
            <div className="mb-2">
              <Image
                className="w-100 p-1"
                src={imgConcertHall01}
                alt="concert hall music republicain"
              />
            </div>
            <div className="mb-2">
              <Image
                className="w-50 p-1"
                src={imgConcertHall03}
                alt="concert hall music republicain"
              />
              <Image
                className="w-50 p-1"
                src={imgConcertHall04}
                alt="concert hall music republicain"
              />
            </div>
            <div className="mb-2">
              <Image
                className="w-100 p-1"
                src={imgConcertHall02}
                alt="concert hall music republicain"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
